

const tab = function () {
	let tabNav = document.querySelectorAll('.tabs-nav__item'), // Выбираем элементы навигации табов
		tabContent = document.querySelectorAll('.tabs-content__item'), // Выбираем самы табы
		tabName; // Переменная для имени таба

	// Проходим циклом по каждому элементу навигации и навешиваем обработчик клика, который вызывает функцию selectTabNav
	tabNav.forEach((item) => {
		item.addEventListener('click', selectTabNav)
	});

	function selectTabNav() {
		tabNav.forEach((item) => {
			// Удаляем активный класс у всех элементов навигации табов
			item.classList.remove('tabs-nav__item_active');
		});
		this.classList.add('tabs-nav__item_active');  // Добавляем активный укласс у элемента по которому кликнули
		tabName = this.getAttribute('data-tab-name'); // Получаем имя таба, который нам нужен
		selectTabContent(tabName); // Запускаем функцию, чтобы показать выбранный таб
	}

	function selectTabContent(tab) {
		// Проходим по всем табам и проверяем есть ли у элемента класс, равный имени таба(переменной tabName). Если есть, то добавляем класс активного таба, если нет, то удаляем этот класс
		tabContent.forEach((item) => {
			const { classList } = item;
			classList.contains(tab) ? classList.add('tabs-content__item_active') : classList.remove('tabs-content__item_active');
		});
	}
};

const sandwich = function () {
	$('.sandwich__open-btn').click(() => {
		$('.sandwich__content').addClass('sandwich__content_opend');
	});
	$('.sandwich__close-btn').click(() => {
		$('.sandwich__content').removeClass('sandwich__content_opend');
	});
};

const dropdown = function () {
	$('.dropdown-item__content').hide();
	$('.dropdown-item__header').click(function (e) {
		$(this).siblings('.dropdown-item__content').slideToggle(200);
		$(this).toggleClass('dropdown-item__header_opend')
	});
};

const galleryThumbs = new Swiper('.gallery-thumbs', {
	spaceBetween: 10,
	slidesPerView: 4,
	freeMode: true,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
});
const galleryTop = new Swiper('.gallery-top', {
	spaceBetween: 10,
	thumbs: {
		swiper: galleryThumbs,
	},
});

tab();
sandwich();
dropdown();

// Переменная для включения/отключения индикатора загрузки
const spinner = $('.ymap-container').children('.loader');
// Переменная для определения была ли хоть раз загружена Яндекс.Карта (чтобы избежать повторной загрузки при наведении)
let check_if_load = false;
// Необходимые переменные для того, чтобы задать координаты на Яндекс.Карте
let myMapTemp,
	myPlacemarkTemp;

// Функция создания карты сайта и затем вставки ее в блок с идентификатором &#34;map-yandex&#34;
function init() {
	let myMapTemp = new ymaps.Map('map-yandex', {
		center: [55.999185, 37.209957], // координаты центра на карте
		zoom: 7, // коэффициент приближения карты
		controls: ['zoomControl', 'fullscreenControl'], // выбираем только те функции, которые необходимы при использовании
	});
	let myPlacemarkTemp = new ymaps.Placemark([55.730138, 37.594238], {
		balloonContent: 'Здесь может быть ваш адрес',
	}, {
		// Опции.
		// Необходимо указать данный тип макета.
		iconLayout: 'default#imageWithContent',
		// Своё изображение иконки метки.
		iconImageHref: '../images/content/map-marker.png',
		// Размеры метки.
		iconImageSize: [50, 50],
		// Смещение левого верхнего угла иконки относительно
		// её "ножки" (точки привязки).
		iconImageOffset: [-25, -50],
	});
	myMapTemp.geoObjects.add(myPlacemarkTemp); // помещаем флажок на карту

	// Получаем первый экземпляр коллекции слоев, потом первый слой коллекции
	let layer = myMapTemp.layers.get(0).get(0);

	// Решение по callback-у для определения полной загрузки карты
	waitForTilesLoad(layer).then(() => {
		// Скрываем индикатор загрузки после полной загрузки карты
		spinner.removeClass('is-active');
	});
}

// Функция для определения полной загрузки карты (на самом деле проверяется загрузка тайлов)
function waitForTilesLoad(layer) {
	return new ymaps.vow.Promise(((resolve, reject) => {
		var tc = getTileContainer(layer), 
readyAll = true;
		tc.tiles.each((tile, number) => {
      if (!tile.isReady()) {
        readyAll = false;
      }
    });
		if (readyAll) {
			resolve();
		} else {
			tc.events.once('ready', () => {
        resolve();
      });
		}
	}));
}

function getTileContainer(layer) {
	for (const k in layer) {
		if (layer.hasOwnProperty(k)) {
			if (
				layer[k] instanceof ymaps.layer.tileContainer.CanvasContainer
        || layer[k] instanceof ymaps.layer.tileContainer.DomContainer
			) {
				return layer[k];
			}
		}
	}
	return null;
}

// Функция загрузки API Яндекс.Карт по требованию (в нашем случае при наведении)
function loadScript(url, callback) {
	let script = document.createElement('script');

	if (script.readyState) {  // IE
		script.onreadystatechange = function () {
			if (script.readyState == 'loaded'
              || script.readyState == 'complete') {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {  // Другие браузеры
		script.onload = function () {
			callback();
		};
	}

	script.src = url;
	document.getElementsByTagName('head')[0].appendChild(script);
}

// Основная функция, которая проверяет когда мы навели на блок с классом &#34;ymap-container&#34;
const ymap = function () {
	$('.ymap-container').mouseenter(() => {
		if (!check_if_load) { // проверяем первый ли раз загружается Яндекс.Карта, если да, то загружаем
	  	// Чтобы не было повторной загрузки карты, мы изменяем значение переменной
			check_if_load = true;

			// Показываем индикатор загрузки до тех пор, пока карта не загрузится
			spinner.addClass('is-active');

			// Загружаем API Яндекс.Карт
			loadScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;loadByRequire=1', () => {
           // Как только API Яндекс.Карт загрузились, сразу формируем карту и помещаем в блок с идентификатором &#34;map-yandex&#34;
           ymaps.load(init);
        });
		}
	},
	);
}

$(() => {
  // Запускаем основную функцию
	ymap();
});